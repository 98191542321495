import { Box, Typography } from "@mui/material";
import { fontFamily } from "@styles/fontFamily";
import { green, text, white } from "@styles/colors";
import { ReactComponent as Logo1 } from "@assets/1.svg";
import { ReactComponent as Logo2 } from "@assets/2.svg";
import { ReactComponent as Logo3 } from "@assets/3.svg";
import { ReactComponent as Logo4 } from "@assets/4.svg";
import { ReactComponent as Logo5 } from "@assets/5.svg";
import { ReactComponent as Logo6 } from "@assets/6.svg";
import { ReactComponent as Logo7 } from "@assets/7.svg";
import { ReactComponent as Logo8 } from "@assets/8.svg";
import { navIds } from "@constants/navIds";
import theme from "@styles/theme";

const classes = {
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    marginTop: 15,
  },
  containerTop: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  containerBottom: {
    display: "flex",
    justifyContent: "space-between",
    gap: 9,
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
    }
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  row: {
    display: "flex",
    alignItems: "start",
    gap: 4,
  },
  rowText: {
    fontFamily: fontFamily.Geist,
    fontSize: 24,
    fontWeight: 400,
    color: text,
    marginTop: '-4px',
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  iconContainer: {
    paddingTop: 0.5
  },
  title: {
    fontFamily: fontFamily.Montserrat,
    fontSize: 32,
    fontWeight: 800,
    color: green,
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  subTitle: {
    fontFamily: fontFamily.Montserrat,
    fontSize: 64,
    fontWeight: 900,
    color: white,
    textAlign: "center",
    fontStyle: "italic",
    [theme.breakpoints.down("lg")]: {
      fontSize: 52,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
  },
};

const Anyone = () => {
  return (
    <Box sx={classes.root} id={navIds.Menu4}>
      <Box sx={classes.containerTop}>
        <Typography sx={classes.title}>on APESWAP</Typography>
        <Typography sx={classes.subTitle}>Anyone can...</Typography>
      </Box>
      <Box sx={classes.containerBottom}>
        <Box sx={classes.column}>
          <Box sx={classes.row}>
            <Box sx={classes.iconContainer}><Logo1 style={{minWidth: "24px", minHeight: '24px'}}/></Box>
            <Typography sx={classes.rowText}>
              Collect the genesis runic satoshis and be part of the market mania
            </Typography>
          </Box>
          <Box sx={classes.row}>
            <Box sx={classes.iconContainer}><Logo2 style={{minWidth: "24px", minHeight: '24px'}}/></Box>
            <Typography sx={classes.rowText}>
              Discover the most exciting cutting edge utility use cases before
              anyone else
            </Typography>
          </Box>
          <Box sx={classes.row}>
            <Box sx={classes.iconContainer}><Logo3 style={{minWidth: "24px", minHeight: '24px'}}/></Box>
            <Typography sx={classes.rowText}>
              Chart the progress of all their favourite Runes projects
            </Typography>
          </Box>
          <Box sx={classes.row}>
            <Box sx={classes.iconContainer}><Logo4 style={{minWidth: "24px", minHeight: '24px'}}/></Box>
            <Typography sx={classes.rowText}>
              Add value to Runes-denominated liquidity pools and begin earning
              yield
            </Typography>
          </Box>
        </Box>
        <Box sx={classes.column}>
          <Box sx={classes.row}>
            <Box sx={classes.iconContainer}><Logo5 style={{minWidth: "24px", minHeight: '24px'}}/></Box>
            <Typography sx={classes.rowText}>
              Bridge EVM & BTC liquidity over to Runes and start trading day one
            </Typography>
          </Box>
          <Box sx={classes.row}>
            <Box sx={classes.iconContainer}><Logo6 style={{minWidth: "24px", minHeight: '24px'}}/></Box>
            <Typography sx={classes.rowText}>
              Browse for new Runes quickly and easily as they release
            </Typography>
          </Box>
          <Box sx={classes.row}>
            <Box sx={classes.iconContainer}><Logo7 style={{minWidth: "24px", minHeight: '24px'}}/></Box>
            <Typography sx={classes.rowText}>
              List Runes tokens permissionlessly to open new markets for leading
              projects
            </Typography>
          </Box>
          <Box sx={classes.row}>
            <Box sx={classes.iconContainer}><Logo8 style={{minWidth: "24px", minHeight: '24px'}}/></Box>
            <Typography sx={classes.rowText}>
              Profit from the brilliant innovation being deployed on BTC’s best
              utility protocol
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Anyone;
