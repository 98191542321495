import { Box, Typography } from "@mui/material";
import { fontFamily } from "@styles/fontFamily";
import { green, text, white } from "@styles/colors";
import { navIds } from "@constants/navIds";
import theme from "@styles/theme";

const classes = {
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    marginTop: 12,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "base-line",
    gap: 10,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    width: 537,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  right: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    width: 648,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  title: {
    fontFamily: fontFamily.Montserrat,
    fontSize: 24,
    fontWeight: 800,
    color: green,
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  subtitle: {
    marginTop: "-10px",
    fontFamily: fontFamily.Montserrat,
    fontSize: 48,
    fontWeight: 900,
    fontStyle: "italic",
    color: white,
    [theme.breakpoints.down("lg")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
  },
  rightP: {
    fontFamily: fontFamily.Geist,
    fontSize: 24,
    fontWeight: 400,
    color: text,
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  rightM: {
    fontFamily: fontFamily.Geist,
    fontSize: 32,
    fontWeight: 900,
    zIndex: 1000,
    color: white,
    [theme.breakpoints.down("lg")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  rightB: {
    fontFamily: fontFamily.Geist,
    fontSize: 24,
    fontWeight: 700,
    color: green,
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
};

const Runes = () => {
  return (
    <Box sx={classes.root} id={navIds.Menu3}>
      <Typography sx={classes.title}>Runes Protocol is...</Typography>
      <Box sx={classes.container}>
        <Box sx={classes.left}>
          <Typography sx={classes.subtitle}>
            a game-changing utility protocol for Bitcoin:
          </Typography>
        </Box>
        <Box sx={classes.right}>
          <Typography sx={classes.rightP}>
            Faster and more efficient than any previous BTC utility standard,
            with less network clutter, more individualization, and cheaper
            mints. Runes are the ultimate fungible token standard for BTC. They
            don’t pollute the network and allow anyone to perform fast
            inscription of satoshis easily and unleash novel utility and
            innovation on Bitcoin.
          </Typography>
          <Typography sx={classes.rightM}>
            Runes went live with the last halving, at block 840,000.
          </Typography>
          <Typography sx={classes.rightB}>
            ApeSwap lets you trade them first.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Runes;
