import { Box, Button, Chip, Typography } from "@mui/material";
import { fontFamily } from "@styles/fontFamily";
import { black, green, text, white } from "@styles/colors";
import EastIcon from "@mui/icons-material/East";
import { navIds } from "@constants/navIds";
import theme from "@styles/theme";

const classes = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    gap: 5,
    marginTop: 15,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    gap: 6,
  },
  right: {
    display: "flex",
    flexWrap: "wrap",
    height: "fit-content",
    gap: 1.5,
    width: 648,
    [theme.breakpoints.down("lg")]: {
      width: 500,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button: {
    width: 326,
    height: 64,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  chip: {
    fontFamily: fontFamily.Geist,
    color: text,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  title: {
    fontFamily: fontFamily.Montserrat,
    fontSize: 64,
    fontWeight: 900,
    color: white,
    fontStyle: "italic",
    [theme.breakpoints.down("lg")]: {
      fontSize: 52,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
  },
  subTitle: {
    fontFamily: fontFamily.Geist,
    fontSize: 24,
    fontWeight: 400,
    color: green,
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  btnText: {
    fontFamily: fontFamily.Geist,
    fontSize: 20,
    fontWeight: 700,
    color: black,
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
    },
  },
};
const ConnectWallet = () => {
  return (
    <Box sx={classes.root} id={navIds.Menu5}>
      <Box sx={classes.left}>
        <Typography sx={classes.title}>Connect your wallet</Typography>
        <Typography sx={classes.subTitle}>Connect your wallet</Typography>
        <Button sx={classes.button} variant={"contained"}>
          <EastIcon style={{ color: black }} />
          <Typography sx={classes.btnText}>CONNECT WALLET</Typography>
        </Button>
      </Box>
      <Box sx={classes.right}>
        <Chip sx={classes.chip} variant={"outlined"} label={"low fees"} />
        <Chip
          sx={classes.chip}
          variant={"outlined"}
          label={"quick transactions"}
        />
        <Chip sx={classes.chip} variant={"outlined"} label={"intuitive UI"} />
        <Chip sx={classes.chip} variant={"outlined"} label={"non-custodial"} />
        <Chip
          sx={classes.chip}
          variant={"outlined"}
          label={"no front-running"}
        />
        <Chip sx={classes.chip} variant={"outlined"} label={"hybrid AMM"} />
        <Chip
          sx={classes.chip}
          variant={"outlined"}
          label={"off-chain order matching engine"}
        />
        <Chip
          sx={classes.chip}
          variant={"outlined"}
          label={"BTC/EVM compatible"}
        />
        <Chip
          sx={classes.chip}
          variant={"outlined"}
          label={"permissionless access"}
        />
      </Box>
    </Box>
  );
};

export default ConnectWallet;
