import { Box, Typography } from "@mui/material";
import { fontFamily } from "@styles/fontFamily";
import {black, green, white} from "@styles/colors";
import { Button } from "@mui/material";
import { navIds } from "@constants/navIds";
import theme from "@styles/theme";
import {ReactComponent as TG} from "@assets/tg.svg";
import {ReactComponent as X} from "@assets/x.svg";
import {ReactComponent as MEDIUM} from "@assets/medium.svg";
import {ReactComponent as DIS} from "@assets/dis.svg";
import EastIcon from "@mui/icons-material/East";
import {links} from "@constants/links";

const classes = {
  root: {
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    gap: 12
  },
  titleContainer: {
      display: 'flex',
      flexDirection: 'column',
    justifyContent: 'center',
    gap: 2
  },
  title: {
    fontFamily: fontFamily.Montserrat,
    fontSize: 32,
    fontWeight: 800,
    color: green,
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  subTitle: {
    fontFamily: fontFamily.Montserrat,
    fontSize: 64,
    fontWeight: 900,
    color: white,
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: 52,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
  },
  btnContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 2
  },
  btn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 12.5,
    height: 64,
    width: 366
  },
  btnText: {
    fontFamily: fontFamily.Geist,
    fontSize: 20,
    fontWeight: 700,
    color: black,
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
    },
  },
  a: {
    textDecoration: 'none'
  }
};

const Footer = () => {
  return (
    <Box sx={classes.root} id={navIds.Menu6}>
      <Box sx={classes.titleContainer}>
        <Typography sx={classes.title}>Discover the Runes</Typography>
        <Typography sx={classes.subTitle}>With Our Community</Typography>
      </Box>
      <Box sx={classes.btnContainer}>
        <Box sx={classes.a} component={'a'} target={"_blank"} rel={"noreferrer"} href={links.tg}><Button sx={{...classes.btn, backgroundColor: '#2ABFEE', "&:hover": {backgroundColor: '#2ABFEE'}}} variant={'contained'}><TG/><Typography sx={classes.btnText}>TELEGRAM</Typography></Button></Box>
        <Box sx={classes.a} component={'a'} target={"_blank"} rel={"noreferrer"} href={links.x}><Button sx={{...classes.btn, backgroundColor: '#2ABFEE', "&:hover":{backgroundColor: '#2ABFEE'}}} variant={'contained'}><X/><Typography sx={classes.btnText}>X/TWITTER</Typography></Button></Box>
        <Box sx={classes.a} component={'a'} target={"_blank"} rel={"noreferrer"} href={links.medium}><Button sx={{...classes.btn, backgroundColor: white, "&:hover":{backgroundColor: white}}} variant={'contained'}><MEDIUM/><Typography sx={classes.btnText}>MEDIUM</Typography></Button></Box>
        <Box sx={classes.a} component={'a'} target={"_blank"} rel={"noreferrer"} href={links.discord}><Button sx={{...classes.btn, backgroundColor: '#5165F6', "&:hover":{backgroundColor: '#5165F6'}}} variant={'contained'}><DIS/><Typography sx={classes.btnText}>DISCORD</Typography></Button></Box>
        <Box sx={classes.a} component={'a'} target={"_blank"} rel={"noreferrer"} href={links.rune}><Button sx={{...classes.btn, backgroundColor: green, "&:hover":{backgroundColor: green}}} variant={'contained'}><EastIcon style={{ color: black }} /><Typography sx={classes.btnText}>RUNE LIBRARY</Typography></Button></Box>
      </Box>
    </Box>
  );
};

export default Footer;
