import { Box, Button, Typography } from "@mui/material";
import { fontFamily } from "@styles/fontFamily";
import { black, green, white } from "@styles/colors";
import main from "@assets/main.png";
import EastIcon from "@mui/icons-material/East";
import theme from "@styles/theme";

const classes = {
  root: {
    marginTop: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    hap: 13.5,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    gap: 6,
  },
  button: {
    width: 326,
    height: 64,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  right: {
    width: "100%",
  },
  img: {
    width: "100%",
  },
  title: {
    fontWeight: 900,
    fontSize: 64,
    fontStyle: "italic",
    fontFamily: fontFamily.Montserrat,
    color: white,
    [theme.breakpoints.down("lg")]: {
      fontSize: 52,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: 40,
    },
  },
  subTitle: {
    fontWeight: 400,
    fontSize: 24,
    fontFamily: fontFamily.Geist,
    color: green,
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      width: "100%",
      fontSize: 18,
    },
  },
  btnTypo: {
    fontWeight: 700,
    fontSize: 18,
    fontFamily: fontFamily.Geist,
    color: black,
  },
};

const Main = () => {
  return (
    <Box sx={classes.root}>
      <Box sx={classes.left}>
        <Typography sx={classes.title}>
          Discover and Trade Runic Satoshis
        </Typography>
        <Typography sx={classes.subTitle}>Soon on ApeSwap</Typography>
        <Button
          sx={classes.button}
          variant={"contained"}
          onClick={() => {
            window.open("https://docsend.com/v/vsckt/apeswap", "_blank");
          }}
        >
          <EastIcon style={{ color: black }} />
          <Typography sx={classes.btnTypo}>Deck</Typography>
        </Button>
      </Box>
      <Box sx={classes.right}>
        <Box sx={classes.img} component={"img"} src={main} alt="" />
      </Box>
    </Box>
  );
};

export default Main;
