import {Box, Chip, Typography, useMediaQuery} from "@mui/material";
import allThe from "@assets/allthe.png";
import allTheMob from "@assets/allTheMob.png";
import { fontFamily } from "@styles/fontFamily";
import { green, white } from "@styles/colors";
import { navIds } from "@constants/navIds";
import theme from "@styles/theme";

const classes = {
  root: {
    width: "100%",
    display: "flex",
    gap: 5,
    alignItems: "center",
    marginTop: 12,
    [theme.breakpoints.down("lg")]: {
      gap: 2,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  commonChip: {
    color: white,
    borderColor: white,
    fontFamily: fontFamily.Geist,
    fontSize: 20,
  },
  rareChip: {
    color: "#3E97FF",
    borderColor: "#3E97FF",
    fontFamily: fontFamily.Geist,
    fontSize: 20,
  },
  epicChip: {
    color: "#D357FF",
    borderColor: "#D357FF",
    fontFamily: fontFamily.Geist,
    fontSize: 20,
  },
  legendaryChip: {
    color: "#FFAE4E",
    borderColor: "#FFAE4E",
    fontFamily: fontFamily.Geist,
    fontSize: 20,
  },
  mythicChip: {
    color: "#00F57F",
    borderColor: "#00F57F",
    fontFamily: fontFamily.Geist,
    fontSize: 20,
  },
  chipContainer: {
    display: "flex",
    gap: 1,
    justifyContent: "end",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center'
    }
  },
  title: {
    fontWeight: 900,
    fontSize: 64,
    fontFamily: fontFamily.Montserrat,
    fontStyle: "italic",
    color: white,
    textAlign: "right",
    [theme.breakpoints.down("lg")]: {
      fontSize: 52,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
      fontSize: 40,
    }
  },
  bottomText: {
    fontWeight: 400,
    fontSize: 24,
    fontFamily: fontFamily.Geist,
    color: green,
    textAlign: "right",
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
      fontSize: 18,
    }
  },
  imgContainer: {
    width: "100%",
    height: 550,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("lg")]: {
      height: 'auto',
    }
  }
};
const AllThe = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box sx={classes.root} id={navIds.Menu1}>
      <Box sx={classes.imgContainer}>
        <img style={{ width: "100%", height: '100%' }} src={isMobile ? allTheMob : allThe} alt="" />
      </Box>
      <Box>
        <Typography sx={classes.title as any}>All the</Typography>
        <Box sx={classes.chipContainer}>
          <Chip sx={classes.commonChip} variant={"outlined"} label={"common"} />
          <Chip sx={classes.rareChip} variant={"outlined"} label={"rare"} />
          <Chip sx={classes.epicChip} variant={"outlined"} label={"epic"} />
          <Chip
            sx={classes.legendaryChip}
            variant={"outlined"}
            label={"legendary"}
          />
          <Chip sx={classes.mythicChip} variant={"outlined"} label={"mythic"} />
        </Box>
        <Typography sx={classes.title as any}>satoshis are available on</Typography>
        <Typography sx={classes.bottomText as any}>on ApeSwap</Typography>
      </Box>
    </Box>
  );
};

export default AllThe;
