import { Box, Card, Typography } from "@mui/material";
import { black, green, white } from "@styles/colors";
import apeBg from "@assets/apebg.png";
import ape from "@assets/ape.png";
import { ReactComponent as Logo } from "@assets/logo_black.svg";
import { ReactComponent as Logo2 } from "@assets/ape2.svg";
import { fontFamily } from "@styles/fontFamily";
import theme from "@styles/theme";
import { navIds } from "@constants/navIds";

const classes = {
  root: {
    height: 560,
    display: "flex",
    gap: 2,
    marginTop: 12,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 'auto'
    }
  },
  img: {
    position: "absolute",
  },
  left: {
    minWidth: 427,
    height: "100%",
    backgroundColor: green,
    padding: theme.spacing(6, 6, 0, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 3,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 3, 0, 3),
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 0,
    }
  },
  right: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: black,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down('md')]: {
      height: 400,
    }
  },
  logoTitle: {
    fontFamily: fontFamily.Montserrat,
    fontSize: 40,
    fontWeight: 800,
    color: black,
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
  },
  logoBottom: {
    fontFamily: fontFamily.Geist,
    fontSize: 24,
    fontWeight: 400,
    color: black,
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  title: {
    fontFamily: fontFamily.Montserrat,
    fontSize: 40,
    fontWeight: 900,
    fontStyle: "italic",
    zIndex: 1000,
    color: white,
    textAlign: "center",
    margin: 5,
    [theme.breakpoints.down("lg")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("md")]: {
      margin: 4,
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      margin: 3
    },
  },
  logoContainer: {
    margin: 5,
    zIndex: 1000,
    [theme.breakpoints.down("md")]: {
      margin: 4
    },
        [theme.breakpoints.down("sm")]: {
      margin: 3
    },
  }
};

const Ape = () => {
  return (
    <Box sx={classes.root} id={navIds.Menu2}>
      <Card sx={classes.left}>
        <Box sx={{ display: "flex", gap: 1.75, alignItems: "center" }}>
          <Logo />
          <Typography sx={classes.logoTitle}>APESWAP</Typography>
        </Box>
        <Typography sx={classes.logoBottom}>
          the decentralised exchange for Bitcoin’s Runes Protocol
        </Typography>
        <Box component={"img"} src={ape} alt={""} sx={{ marginTop: "auto" }} />
      </Card>
      <Card sx={classes.right}>
        <Box sx={classes.logoContainer}>
          <Logo2/>
        </Box>
        <Typography sx={classes.title}>
          The old becomes new as ancient treasure yields new wealth
        </Typography>
        <Box sx={classes.img} component={"img"} src={apeBg} alt="" />
      </Card>
    </Box>
  );
};

export default Ape;
