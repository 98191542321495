export enum navIds {
  Menu1 = "Menu1",
  Menu2 = "Menu2",
  Menu3 = "Menu3",
  Menu4 = "Menu4",
  Menu5 = "Menu5",
  Menu6 = "Menu6",
}

export const navIdsArr: navIds[] = Object.values(navIds).map((item) => item);
