import React from "react";
import { Container } from "@mui/material";
import Header from "@components/Header";
import Main from "@components/Main";
import AllThe from "@components/AllThe";
import Ape from "@components/Ape";
import Runes from "@components/Runes";
import Anyone from "@components/Anyone";
import ConnectWallet from "@components/ConnectWallet";
import Footer from "@components/Footer";

const App = () => {
  return (
    <Container maxWidth={"lg"}>
      <Header />
      <Main />
      <AllThe />
      <Ape />
      <Runes />
      <Anyone />
      <ConnectWallet />
      <Footer />
    </Container>
  );
};

export default App;
