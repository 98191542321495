import {Box, Button, Card, Typography, useMediaQuery} from "@mui/material";
import theme from "@styles/theme";
import { ReactComponent as Logo } from "@assets/logo.svg";
import { fontFamily } from "@styles/fontFamily";
import { black, white } from "@styles/colors";
import { navIds, navIdsArr } from "@constants/navIds";
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from "@mui/material";
import { Drawer } from "@mui/material";
import {useState} from "react";
import CloseIcon from '@mui/icons-material/Close';

const classes = {
  root: {
    marginTop: 1.5,
    width: '100%'
  },
  card: {
    width: '100%',
    height: 72,
    padding: theme.spacing(2, 4, 2, 4),
    backgroundColor: "rgba(30, 30, 30, 0.80)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    }
  },
  logoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2.5,
  },
  nav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2,
    [theme.breakpoints.down('lg')]: {
      gap: 0,
    }
  },
  mobileNav: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'start',
    backgroundColor: black,
    width: '100%',
    height: '100%',
    gap: 2,
  },
  button: {
    width: 204,
    height: 48,
    [theme.breakpoints.down('lg')]: {
      width: 'auto',
    }
  },
  logoTypo: {
    fontFamily: fontFamily.Montserrat,
    fontSize: 24,
    fontWeight: 800,
    color: white,
    [theme.breakpoints.down('lg')]: {
      fontSize: 20
    }
  },
  navItemTypo: {
    fontFamily: fontFamily.Geist,
    fontSize: 18,
    fontWeight: 700,
    color: white,
    [theme.breakpoints.down('lg')]: {
      fontSize: 16
    }
  },
  btnTypo: {
    fontFamily: fontFamily.Geist,
    fontSize: 18,
    fontWeight: 700,
    color: black,
    [theme.breakpoints.down('lg')]: {
      fontSize: 16
    }
  }
};

const scrollTo = (id: navIds) => {
  document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
};

const Header = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Box sx={classes.root}>
      <Card sx={classes.card}>
        <Box sx={classes.logoContainer}>
          <Logo />
          <Typography
            sx={classes.logoTypo}
          >
            APESWAP
          </Typography>
        </Box>
        {isMobile ?
          <><IconButton onClick={() => setOpen(true)}><MenuIcon style={{ color: white }}/></IconButton>
            <Drawer
                anchor={'top'}
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                  sx: {height: '100%', padding: 2, backgroundColor: black}
                }}
            >
              <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}><IconButton onClick={() => setOpen(false)}><CloseIcon style={{ color: white }}/></IconButton></Box>
              <Box sx={classes.mobileNav}>
              </Box>
              <Button sx={classes.button} variant={"contained"}>
                <Typography
                    sx={classes.btnTypo}
                >
                  TRADE NOW
                </Typography>
              </Button>
            </Drawer>
          </> :
            <>
              <Box sx={classes.nav}>
              </Box>
              <Button sx={classes.button} variant={"contained"}>
                <Typography
                    sx={classes.btnTypo}
                >
                  TRADE NOW
                </Typography>
              </Button>
            </>
        }
      </Card>
    </Box>
  );
};

export default Header;
