import { Components } from "@mui/material";
import {black, green, white} from "@styles/colors";

const getOverridesComponents = (): Components => ({
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Geist';
        font-style: normal;
        font-weight: 400;
        src: local('Geist-Regular'), url(./fonts/Geist-Regular.woff2) format('woff2');
      }
      @font-face {
        font-family: 'Geist';
        font-style: normal;
        font-weight: 700;
        src: local('Geist-Bold'), url(./fonts/Geist-Bold.woff2) format('woff2');
      }
      @font-face {
        font-family: 'Geist';
        font-style: normal;
        font-weight: 800;
        src: local('Geist-Black'), url(./fonts/Geist-Black.woff2) format('woff2');
      }
      @font-face {
        font-family: 'GeistMono';
        font-style: normal;
        font-weight: 400;
        src: local('GeistMono-Regular'), url(./fonts/GeistMono-Regular.woff2) format('woff2');
      }
      @font-face {
        font-family: 'GeistMono';
        font-style: normal;
        font-weight: 700;
        src: local('GeistMono-Bold'), url(./fonts/GeistMono-Bold.woff2) format('woff2');
      }
      @font-face {
        font-family: 'GeistMono';
        font-style: normal;
        font-weight: 800;
        src: local('GeistMono-Black'), url(./fonts/GeistMono-Black.woff2) format('woff2');
      }
    `,
  },
  MuiButton: {
    styleOverrides: {
      root: {},
      contained: {
        backgroundColor: green,
        borderRadius: 8,
        "&:hover": {
          background: green,
        },
      },
      text: {
        color: white,
        borderRadius: 8,
        textTransform: "none",
        "&:hover": {
          color: green,
        },
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: black,

      }
    }
  },
});

export default getOverridesComponents;
